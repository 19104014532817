import type { DataObjectOptions, RecordSourceOptions, ItemModel, DataItemModel, RecordSourceCancelableEvent, ContextFilterType, RecordSourceFieldType } from 'o365.modules.DataObject.Types.ts';
import type { DataObjectField } from 'o365.modules.DataObject.Fields.ts';
import type { IDestroyOptions, ICreateOptions, IUpdateOptions } from 'o365.modules.DataObject.DataHandler.ts';
import type DataObject from 'o365.modules.DataObject.ts';

import DataHandler from 'o365.modules.DataObject.DataHandler.ts';
import context from 'o365.modules.Context.ts';
import $t from 'o365.modules.translate.ts';
import BulkOperation from 'o365.modules.utils.BulkOperation.ts'
import logger from 'o365.modules.Logger.ts';

export default class RecordSource<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _filterString: string | null;
    private _groupByFilter?: string;
    private _contextFilterSet: boolean = false;
    private _contextFilter: ContextFilterType | null = null;
    private _autoLoadOnContextChange = true;
    private _cancelContextListener: null | (() => void) = null
    private _savingPromise?: Promise<T[][]>;
    private _savingByIndexPromise: Map<number, Promise<T[]>> = new Map();
    private _expandView?: boolean;
    private _definitionProc?: string;
    private _definitionProcParameters?: Record<string, any>
    private _sqlStatementParameters?: Record<string, any>
    private _contextId?: number | null = undefined;

    private _retrieveByPrimKey = new BulkOperation<string, T[]>({
        bulkOperation: async (pItems) => {
            const data = await this.bulkRetrieve(pItems.map(x => x.value), 'PrimKey');
            pItems.forEach(item => {
                const rows = data.filter(x => x.PrimKey === item.value);
                item.res(rows);
            });
        },
        bulkSize: 500
    });

    private _retrieveByID = new BulkOperation<number | string, T[]>({
        bulkOperation: async (pItems) => {
            const data = await this.bulkRetrieve(pItems.map(x => x.value), 'ID');
            pItems.forEach(item => {
                const rows = data.filter(x => x.ID === item.value);
                item.res(rows);
            });
        },
        bulkSize: 500
    });

    private _bulkSaveOperation = new BulkOperation<DataItemModel<T>, DataItemModel<T>>({
        bulkOperation: async (pItems) => {
            try {
                await this.bulkSaveItems(pItems.map(item => item.value));
                pItems.forEach(item => item.res(item.value));
            } catch (ex) {
                pItems.forEach(item => item.rej(ex));
            }
        }
    })

    /** Debounce for loading selected fields data */
    private _selectedFieldsLoadDebounce: number | null = null;
    private _prevWhereClause: string | null = null;
    private _prevWhereClauseSingle: string | null = null;
    private _selectedFields: DataObjectField[] = [];
    private _bulkSave: boolean = false;

    searchString?: string;
    searchFunction?: string;

    /** Visible selected fields that should be loaded */
    get selectFields(): RecordSourceFieldType[] {
        if (this._selectedFields.length > 0) {
            return this._selectedFields.map(field => field.item);
        } else {
            return this._dataObject.fields.fields.map(field => field.item);
        }
    }

    get fields() {
        return this._dataObject.fields.fields;
    }

    get contextFilterSet() {
        return this._contextFilterSet;
    }

    /** When set to true will stop auto loading the dataobject on context changes with context filter enabled */
    get autoLoadOnContextChange() { return this._autoLoadOnContextChange; }
    set autoLoadOnContextChange(value) { { this._autoLoadOnContextChange = value; } }

    /** Current where clause used in DataHandler operations */
    whereClause: string;
    /** Last loaded combined where clause */
    get prevWhereClause() { return this._prevWhereClause; } // TODO: Rename to prevCombinedWhereClause and update usage
    get prevWhereClauseSingle() { return this._prevWhereClauseSingle; } // TODOL: Rename to prevWhereClause
    /** Developer set where clause combined with system set where clauses and master details string */
    get combinedWhereClause() {
        const masterDetailsString = this._dataObject.masterDetails.getFilterString();
        return masterDetailsString ? this.getWhereClause() + masterDetailsString : this.getWhereClause();
    }
    /**
     * Currently applied filter string. Should not be modified 
     * directly, must be changed through FilterObject.
     */
    get filterString() { return this._filterString ? this._filterString : null; }
    set filterString(value) { this._filterString = value; }
    /**
     * TODO(Augustas): Description
     */
    get groupByFilter() { return this._groupByFilter; }
    set groupByFilter(value) { this._groupByFilter = value; }

    get expandView() { return this._expandView; }
    set expandView(pValue) { this._expandView = pValue; }

    get definitionProc() { return this._definitionProc; }
    set definitionProc(pValue) { this._definitionProc = pValue; }

    /** When enabled will consolidate save requests into a bulk insert and bulk update requests */
    get bulkSave() { return this._bulkSave; }
    set bulkSave(pValue) { this._bulkSave = pValue; }

    get contextId() { return this._contextId; }
    set contextId(pValue) { this._contextId = pValue; }

    get definitionProcParameters() {
        return this._definitionProcParameters;
    }
    set definitionProcParameters(pValue) {
        this._definitionProcParameters = pValue;
    }

     /** @deprecated due to spelling typo */
    get sqlStatemntParameters() {
        logger.warn('recordSource.sqlStatemntParameters is depricated due to typo, use sqlStatementParameters');
        return this._sqlStatementParameters;
    }
    set sqlStatemntParameters(pValue) {
        logger.warn('recordSource.sqlStatemntParameters is depricated due to typo, use sqlStatementParameters');
        this._sqlStatementParameters = pValue;
    }

    get sqlStatementParameters() { return this._sqlStatementParameters; }
    set sqlStatementParameters(pValue) { this._sqlStatementParameters = pValue; }

    get savingPromise(){
        return this._savingPromise;
    }

    /** Select distinct rows */
    distinctRows: boolean;
    /** Number of max records to load */
    maxRecords: number;
    /** The offset */
    skip: number = 0;
    /** Load recents option, mainly used by lookups */
    loadRecents: boolean;
    /** Don't allow updates if Updated value in database is differnt from currenly loaded one */
    optimisticLocking: boolean;
    /** When set to false the DataHandler will stop showing toasts when errors occur */
    showToastsOnDataErrors = true;

    whereObject?: any;

    constructor(pOptions: DataObjectOptions<T>, pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this._filterString = pOptions.filterString ?? '';
        this.whereClause = pOptions.whereClause ?? '';
        this.distinctRows = pOptions.distinctRows ?? false;
        this.maxRecords = pOptions.maxRecords ?? 50;
        this.loadRecents = pOptions.loadRecents ?? false;
        this.optimisticLocking = pOptions.optimisticLocking ?? false;
        this._expandView = pOptions.expandView;
        this._definitionProc = pOptions.definitionProc;
        this._sqlStatementParameters = pOptions.sqlStatemntParameters;
        this._definitionProcParameters = pOptions.definitionProcParameters;

        if (this.optimisticLocking) {
            this._dataObject.fields.addFieldIfExists({ name: 'Updated', type: 'datetime' });
        }
    }

    /** Generate options from this record source for DataHandler requests */
    getOptions(pOptions?: {
        includeFilterObjects?: boolean
    }) {
        let options: RecordSourceOptions = {
            dataSourceId: this._dataObject.id,
            viewName: this._dataObject.viewName,
            distinctRows: this.distinctRows,
            skip: this.skip,
            fields: this.selectFields,
            loadRecents: this.loadRecents,
            maxRecords: this.maxRecords,
            whereClause: this.getWhereClause(),
            masterDetailString: this._dataObject.masterDetails.getFilterString() ?? undefined,
            filterString: this.filterString ?? undefined,
            optimisticLocking: this.optimisticLocking ?? undefined,
            searchString: this.searchString,
            searchFunction: this.searchFunction,
            propertiesWhereClause: this._dataObject.hasPropertiesData ? this._dataObject.propertiesData?.propertiesWhereClause : undefined,
            expandView: this._expandView,
            definitionProc: this._definitionProc
        };

        if (this.whereObject) {
            options.whereObject = this.whereObject;
        }

        if (options.expandView || this.definitionProc || this.contextId) {
            options.contextId = this.contextId === undefined ? context.id : this.contextId;
        }

        if (this.sqlStatementParameters) {
            options.sqlStatementParameters = this.sqlStatementParameters;
        }
        if (this.definitionProcParameters) {
            options.definitionProcParameters = this.definitionProcParameters;
        } 

        if (pOptions?.includeFilterObjects || this._dataObject.clientSideFiltering || this.whereObject) {
            options.masterDetailObject = this._dataObject.masterDetails.getFilterObject() ?? undefined;
            options.filterObject = this._dataObject.filterObject.filterObject;
        }

        return options;
    }

    async refreshRow(pIndex?: number) {
        const index = pIndex ?? this._dataObject.currentIndex;
        if (index == null) { return; }
        const item = this._dataObject.storage.data[index];
        if (!item?.primKey) { return; }
        const data = await this.getRowByPrimKey(item.primKey!);
        if (data == null || data.length === 0) {
            this._dataObject.remove(item.index);
            return;
        }
        if (data.length > 1) {
            logger.warn('Refresh current returned more than one record');
        }
        return this._dataObject.storage.updateItem(item.index, data[0], true);
    }

    getRowByPrimKey(pPrimKey: string) {
        return this._retrieveByPrimKey.addToQueue(pPrimKey);
    }

    getRowById(pId: string | number) {
        return this._retrieveByID.addToQueue(pId);
    }

    async refreshRowByPrimKey(pPrimKey: string, pOptions: {
        returnExisting: boolean
        appendRowCount: boolean
    } = {
            appendRowCount: true,
            returnExisting: false
        }) {
        const record = this._dataObject.storage.getItemByPrimKey(pPrimKey);
        if (record == null) {
            const data = await this.getRowByPrimKey(pPrimKey);
            const newRecord = this._dataObject.createNew(data?.[0], false, pOptions.appendRowCount);
            newRecord.state.isNewRecord = false;
            newRecord.reset();
            return newRecord;
        } else {
            if (pOptions.returnExisting) {
                return record;
            } else {
                return this.refreshRow(record.index);
            }
        }
    }

    async refreshRowsByPrimKeys(pPrimKeys: string[]) {
        const promises = pPrimKeys.map(primKey => this.refreshRowByPrimKey(primKey));
        return Promise.all(promises);
    }

    async refreshRowById(pId: string | number, pOptions: {
        returnExisting: boolean
        appendRowCount: boolean
    } = {
            appendRowCount: true,
            returnExisting: false
        }) {
        const record = this._dataObject.storage.getItemById(pId);
        if (record == null) {
            const data = await this.getRowById(pId);
            const newRecord = this._dataObject.createNew(data?.[0], false, pOptions.appendRowCount);
            newRecord.state.isNewRecord = false;
            newRecord.reset();
            return newRecord;
        } else {
            if (pOptions.returnExisting) {
                return record;
            } else {
                return this.refreshRow(record.index);
            }
        }
    }

    async refreshRowsByIds(pIds: (string | number)[]) {
        return pIds.map(id => this.refreshRowById(id));
    }

    async refreshRowsByFilter(pFilterString: string) {
        const options = this.getOptions();
        options.maxRecords = -1;
        options.skip = 0;
        options.filterString = pFilterString;
        const data = await this.retrieve(options);

        const storageMap = new Map<any, number>();
        const uniqueField = this._dataObject.fields.uniqueField ?? 'PrimKey';
        this._dataObject.storage.data.forEach(item => {
            storageMap.set(item.item[uniqueField], item.index);
        });

        const newItems: T[] = [];
        const result: DataItemModel<T>[] = [];
        data.forEach(item => {
            const recordIndex = storageMap.get(item[uniqueField]);
            if (recordIndex != null) {
                result.push(this._dataObject.storage.updateItem(recordIndex, item, true)!);
            } else {
                newItems.push(item);
            }
        });
        if (newItems.length > 0) {
            result.push(...this._dataObject.storage.setItems(newItems, false));
            if (this._dataObject.hasDynamicLoading) {
                if (this._dataObject.dynamicLoading) {
                    this._dataObject.dynamicLoading.setItems(this._dataObject.storage.data, 0);
                    if (this._dataObject.dynamicLoading.onDataLoaded) {
                        this._dataObject.dynamicLoading.onDataLoaded(true);
                    }
                }
            }
        }
        return result;
    }

    async save(pIndex?: number) {
        if (pIndex == null) {
            if (this._savingPromise) {
                await this._savingPromise;
            }
            const savingPromises = this._dataObject.storage.changes.map(row => this._savingByIndexPromise.get(row.index)).filter(x => x != null);
            if (this._dataObject.batchDataEnabled) {
                this._dataObject.batchData.storage.changes.forEach(row => {
                    const savingPromise = this._savingByIndexPromise.get(row.index);
                    if (savingPromise) {
                        savingPromises.push(savingPromise);
                    }
                });
            }
            if (savingPromises.length > 0) {
                await Promise.all(savingPromises);
            }
            const changes = this._dataObject.storage.changes;
            if (this._dataObject.batchDataEnabled) {
                this._dataObject.batchData.storage.changes.forEach(row => {
                    changes.push(row);
                });
            }
            this._savingPromise = this.saveChanges(changes).finally(() => {
                this._savingPromise = undefined;
            });
            return await this._savingPromise;
        } else {
            const savingPromise = this._savingByIndexPromise.get(pIndex);
            if (savingPromise) {
                await savingPromise;
            }
            return this.saveChanges(this._dataObject.storage.changes.filter(x => x.index === pIndex));
        }
    }

    async saveChanges(pChanges: DataItemModel<T>[]) {
        const promises: Promise<T[]>[] = [];
        pChanges.filter(x => !x.disableSaving).forEach(row => {
            const options: RecordSourceOptions & RecordSourceCancelableEvent = this.getOptions();
            options.uniqueTable = this._dataObject.uniqueTable;
            if (row.isSaving || row.error != null || !row.hasChanges) { return; }

            const handlerOperation = row.primKey ? 'update' : 'create';
            if ((handlerOperation === 'update' && !this._dataObject.allowUpdate) || (handlerOperation === 'create' && !this._dataObject.allowInsert)) {
                // DataObject does not allow update or instert. Show warning toast
                const errorMessage = (handlerOperation === 'update' && !this._dataObject.allowUpdate)
                    ? `Update not allowed for DataObject ${this._dataObject.id}`
                    : `Insert not allowed for DataObject ${this._dataObject.id}`;
                import('o365.controls.alert.ts').then(alertModule => {
                    alertModule.default(errorMessage, 'warning');
                });
                return;
            }

            options.values = row.changes ?? {};
            const beforeSavePromise = this._getPromiseWithResolve();
            options.eventPromise = beforeSavePromise.promise;
            this._dataObject.emit('BeforeSave', options, row.item, row);
            if (row.primKey) {
                // Prepare options for update operation
                options.values.PrimKey = row.primKey;
                if (this.optimisticLocking) {
                    options.values.Updated = row.Updated;
                }
                this._dataObject.emit('BeforeUpdate', options, row.item);
            } else {
                // Prepare options for create operation
                if (this._dataObject.masterDetails.isSet) {
                    // This is a details DataObject, get bound master values
                    options.values = { ...options.values, ...this._dataObject.masterDetails.getMasterDetailRowForInsert() };
                }
                if (this._contextFilterSet && this._dataObject.fields.OrgUnit_ID && options.values.OrgUnit_ID === undefined) {
                    options.values.OrgUnit_ID = context.id;
                }
                if (options.values.PrimKey) {
                    delete options.values.PrimKey;
                }
                if (row.defaultValues) {
                    // Get default values from DataItem
                    Object.keys(row.defaultValues).forEach(key => {
                        if (options.values == null) { options.values = {}; }
                        if (!options.values.hasOwnProperty(key) && row.defaultValues[key] != undefined) {
                            options.values[key] = row.defaultValues[key];
                        }
                    });
                }

                this._dataObject.fields.fields.filter(field => {
                    // Get default values from fields
                    if (options.values![field.name] === undefined) {
                        const defaultValue = typeof field.defaultValueFunction === 'function' ? field.defaultValueFunction() : field.defaultValue;
                        options.values![field.name] = defaultValue;
                    }
                });

                this._dataObject.emit('BeforeCreate', options, row.item);
            }
            beforeSavePromise.resolve();
            if (options.cancelEvent === true) {
                if (!options.skipRowReset) {
                    row.reset();
                }
                return promises.push(Promise.resolve([]));
            }
            options.eventPromise = undefined;
            if (Object.keys(options.values).filter(x => x !== 'PrimKey').length === 0) {
                return promises.push(Promise.resolve([]));
            }
            row.state.isSaving = true;
            const doBulkSave = this.bulkSave && pChanges.length > 1;
            const savePromise = doBulkSave
                ? this._bulkSaveOperation.addToQueue(row).then(item => [item!.item])
                : this._saveChange(row, handlerOperation, { ...options, operation: handlerOperation }).finally(() => {
                    this._savingByIndexPromise.delete(row.index);
                });
            this._savingByIndexPromise.set(row.index, savePromise);
            promises.push(savePromise);
            return;
        });

        return Promise.all(promises);
    }

    /**
     * Update row count based on the current state of the DataObject
     */
    updateRowCount(pOptions?: RecordSourceOptions) {
        let lastPageReached = false;
        if (pOptions) {
            const possibleMaxRecords = (pOptions.skip ?? 0) + (pOptions.maxRecords ?? this.maxRecords);
            if (pOptions.maxRecords !== -1 && this._dataObject.storage.data.length < possibleMaxRecords) {
                this._dataObject.state.rowCount = this._dataObject.storage.data.length;
                lastPageReached = true;
            } else if (pOptions.skip === 0 && pOptions.maxRecords === -1) {
                this._dataObject.state.rowCount = this._dataObject.storage.data.length;
                lastPageReached = true;
            } else if (!pOptions.skip) {
                this._dataObject.state.isRowCountLoaded = false;
                this._dataObject.state.rowCount = null;
            }
        } else {
            this._dataObject.state.isRowCountLoaded = false;
            this._dataObject.state.rowCount = null;
        }
        if (this._dataObject.hasDynamicLoading) {
            this._dataObject.dynamicLoading.lastPageReached = lastPageReached;
        }
    }

    /** Load the row count for this DataObject */
    async loadRowCount(pOptions?: RecordSourceOptions & { timeout?: number }, pRemoveFilterString = false) {
        this._dataObject.state.isRowCountLoading = true;

        const options = pOptions ? { ...this.getOptions(), ...pOptions } : this.getOptions();

        if (pRemoveFilterString) {
            options.filterString = undefined;
        }

        try {
            const result: any = await this._dataObject.dataHandler.rowCount(options);
            this._dataObject.state.isRowCountLoaded = true;
            this._dataObject.state.rowCount = result.total ? result.total: result;
        } catch (ex) {

        } finally {
            this._dataObject.state.isRowCountLoading = false;
        }
    }

    /** Get the current sort order array from selected fiels */
    getSortOrder() {
        return this._dataObject.fields.fields.filter(field => !!field.sortDirection).sort((a, b) => a.sortOrder! - b.sortOrder!).map(field => {
            return {
                [field.name]: field.sortDirection ?? 'asc'
            };
        });
    }

    /**
     * Set the current sort order for the selected fields
     * @param pSortOrder
     * @param pClean when `false` will append the supplied sort order instead of setting it anew
     */
    setSortOrder(pSortOrder: Record<string, 'asc' | 'desc'>[], pClean = true, pSkipEventEmit = false) {
        if (pClean) {
            this._dataObject.fields.fields.filter(field => field.sortOrder != null || field.sortDirection != null).forEach(field => {
                field.sortOrder = null;
                field.sortDirection = null;
            });
        }

        pSortOrder.forEach((item, index) => {
            Object.entries(item).forEach(([fieldName, direction]) => {
                const field = this._dataObject.fields[fieldName];
                if (field) {
                    field.sortOrder = index + 1;
                    field.sortDirection = direction;
                }
            });
        });

        if (!pSkipEventEmit) {
            this._dataObject.emit('SortOrderChanged');
        }
    }

    async retrieve(pOptions: Partial<RecordSourceOptions>) {
        let options = this.getOptions();

        if (pOptions) {
            pOptions = { ...options, ...pOptions }
        } else {
            pOptions = options;
        }

        let data: T[];
        if (this._dataObject.dataHandler instanceof DataHandler) {
            data = await this._dataObject.dataHandler.request('retrieve', pOptions);
        } else {
            data = await this._dataObject.dataHandler.retrieve(pOptions);
        }
        return data;
    }

    /**
     * Clear filter string from this recrod source.  
     * Used by FilterObject
     */
    clearFilter() {
        this.filterString = '';
    }

    /** Used by FilterObject */
    getItemsClone() {
        return this._dataObject.filterObject.getActiveClone();
    }

    /** Get the current where clause combined with system filters  */
    getWhereClause() {
        const whereClauses: string[] = [];
        if (this.whereClause) { whereClauses.push('(' + this.whereClause + ')'); }
        if (this._contextFilter) { whereClauses.push(this.getContextFilterString()); }
        if (this.groupByFilter) { whereClauses.push(this.groupByFilter); }
        return whereClauses.join(' AND ');
    }

    /** Generate the fitler string for currrent context using the current `contextFilter` */
    getContextFilterString() {
        if (this._contextFilter == null) {
            return '';
        } else if (typeof this._contextFilter === 'function') {
            return this._contextFilter(context.idPath, context.id);
        } else {
            return `${this._contextFilter['idPathField']} LIKE '${context.idPath}%'`;
        }
    }

    /**
     * Helper function for setting up context filtering. To enable context filtering 
     * you should call `dataObject.enableContextFilter(pOptions)` instead.
     */
    setContextFilter(pOptions: ContextFilterType | null) {
        const setDefaultFieldValues = (value?: number) => {
            const orgunitField = this._dataObject.fields['OrgUnit_ID'];
            if (orgunitField) { orgunitField.defaultValue = value }
            const domainField = this._dataObject.fields['Domain_ID'];
            if (domainField) { domainField.defaultValue = value }
        };

        if (this._cancelContextListener) {
            this._cancelContextListener();
            this._cancelContextListener = null;
            setDefaultFieldValues();
        }

        this._contextFilter = pOptions ?? null;
        if (pOptions == null) {
            this._contextFilterSet = false;
        } else {
            setDefaultFieldValues(context.id);
            this._contextFilterSet = true;
            this._cancelContextListener = context.on('Change', (orgunit) => {
                setDefaultFieldValues(orgunit.id);
                if (this._autoLoadOnContextChange) {
                    this._dataObject.load();
                }
            });
        }
    }

    loadIfWhereClausNotChanged(pOptions?: RecordSourceOptions) {
        logger.warn(`${this._dataObject.id}.recordSource.loadIfWhereClausNotChanged is deprecated, use loadIfWhereClauseChanged. 
        This function always used to only load when the whereclause was diffrent from previous the load, the new name reflects this better.`);
        return this.loadIfWhereClauseChanged(pOptions);
    }
    /**
     * Load the DatObject if the previous where clause is difrent or if 
     * the DataObject isn't loaded once.
     */
    loadIfWhereClauseChanged(pOptions?: RecordSourceOptions) {
        if (this.prevWhereClause !== this.combinedWhereClause || !this._dataObject.state.isLoaded) {
            return this._dataObject.load(pOptions);
        } else {
            return Promise.resolve(undefined);
        }
    }

    private async _saveChange(pRow: DataItemModel<T>, pHandler: 'create' | 'update', pOptions: RecordSourceOptions & { operation: 'create' | 'update' },) {
        try {
            const data = await this._dataObject.dataHandler[pHandler](pOptions);
            if (data.length === 0) {
                throw `${pHandler} did not return any data`;
            }
            if (!pRow.isBatchRecord) {
                this._dataObject.storage.updateItem(pRow.index, data[0], true);
            } else {
                const index = this._dataObject.batchData.getInversedIndex(pRow.index);
                this._dataObject.batchData.storage.updateItem(index, data[0], true);
            }
            const wasNewRecord = pRow.state.isNewRecord;
            pRow.state.isNewRecord = false;
            this._dataObject.emit('AfterSave', pOptions, pRow.item, pRow);
            if (wasNewRecord && pRow.current) {
                // Load detail objects after save when current row was new record
                this._dataObject.masterDetails.loadDetailDataObjects();
            }
            return data;
        } catch (ex: any) {
            // TODO(Augustas): Change optimistic locking handling into typed exceptions
            if (ex && ex.hasOwnProperty('status')) {
                if (ex['status'] === 409) {
                    const { default: o365_confirm } = await import('o365.controls.confirm.ts');
                    try {
                        await o365_confirm({
                            message: $t(ex['error']),
                            title: $t('Saving conflict'),
                            btnTextOk: $t('Refresh row'),
                            btnClassOk: $t('btn-warning'),
                        });
                        pRow.cancelChanges();
                        await this.refreshRow(pRow.index);
                        this._dataObject.emit('ChangesCancelled', [pRow]);
                    } catch (_ex) {
                        pRow.updateError(ex['error']);
                        throw ex;
                    }
                }
            }
            pRow.updateError(ex as Error);
            throw ex;
        } finally {
            this._dataObject.state.changedDate = new Date();
        }
    }

    /**
    * Send a request to delete an item at the given index. 
    * If no index given then will use the current set one.
    */
    async delete(pIndex?: number | null, pItem?: DataItemModel<T> & { o_DeleteConfirm?: boolean }) {
        if (pIndex == null) {
            pIndex = this._dataObject.currentIndex;
        }
        if (pIndex == null) { return false; }

        if (!this._dataObject.allowDelete) {
            this._showAlert(`Delete not allowed for DataObject: ${this._dataObject.id}`, 'warning');
            return false;
        }
        const item: DataItemModel<T> & { o_DeleteConfirm?: boolean } = this._dataObject.storage.data[pIndex] ?? pItem;
        if (item == null) { return false; }

        if (this._dataObject.deleteConfirm) {
            if (item.o_DeleteConfirm) { return false; }
            const { default: confirmControl } = await import('o365.controls.confirm.ts');
            const confirmOptions = {
                message: this._dataObject.deleteConfirmOptions?.message ?? $t('Are you sure you want to delete?'),
                title: this._dataObject.deleteConfirmOptions?.title ?? $t('Delete confirm'),
                btnTextOk: this._dataObject.deleteConfirmOptions?.btnTextOk ?? $t('Delete'),
                btnTextCancel: this._dataObject.deleteConfirmOptions?.btnTextCancel,
                btnClassOk: this._dataObject.deleteConfirmOptions?.btnClassOk ?? 'btn-primary',
                btnClassCancel: this._dataObject.deleteConfirmOptions?.btnClassCancel ?? 'btn-outline-primary',
            };
            try {
                item.o_DeleteConfirm = true;
                await confirmControl(confirmOptions);
            } catch (_) {
                return false;
            } finally {
                delete item.o_DeleteConfirm;
                this._dataObject.state.changedDate = new Date();
            }
        }

        const options: RecordSourceOptions & RecordSourceCancelableEvent = this.getOptions();

        options.uniqueTable = this._dataObject.uniqueTable;
        options.values = {
            PrimKey: item.PrimKey
        };
        this._dataObject.emit('BeforeDelete', options, item);
        if (options.cancelEvent === true) { return false; }

        item.state.isDeleting = true;
        try {
            if (options.values.PrimKey)
                await this._dataObject.dataHandler.destroy(options);
            if (!item.isBatchRecord) {
                this._dataObject.storage.removeItem(item.index);
                if (this._dataObject.state.rowCount) {
                    this._dataObject.state.rowCount -= 1;
                }
            }
            this._dataObject.emit('AfterDelete', options, item);
            if (this._dataObject.hasDynamicLoading) {
                this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data, { skip: 0 });
            }

            if (item.current) {
                if (this._dataObject.data[pIndex] != null) {
                    this._dataObject.setCurrentIndex(pIndex, true);
                } else if (pIndex - 1 >= 0 && this._dataObject.data[pIndex - 1] != null) {
                    this._dataObject.setCurrentIndex(pIndex - 1, true);
                } else {
                    this._dataObject.unsetCurrentIndex();
                }
            } else if (this._dataObject.currentIndex != null && this._dataObject.currentIndex > pIndex) {
                // Items shifted, update current index without state changes
                this._dataObject.updateCurrentIndex(this._dataObject.currentIndex - 1);
            }
            return true;
        } catch (ex) {
            item.updateError(ex as Error);
            return false;
        }
    }

    /**
     * Set item as deleted by index
     * If no index is provided will use current index
     */
    async softDelete(pIndex?: number, pItem?: DataItemModel<T> & { o_DeleteConfirm?: boolean }) {
        if (pIndex == null) {
            if (this._dataObject.currentIndex == null) {
                logger.warn(`${this._dataObject.id} no index for soft delete provided and the current index is unset`);
                return false;
            } else {
                pIndex = this._dataObject.currentIndex;
            }
        }

        if (this._dataObject.softDeleteField == null) {
            if (this._dataObject.fields.viewDefinition.findIndex(x => x.fieldName === 'Deleted') !== -1) {
                this._dataObject.setSoftDeleteField('Deleted');
            } else {
                logger.warn(`${this._dataObject.id} does not have [Deleted] column, use ${this._dataObject.id}.setSoftDeleteField('FieldName') to set the field used for marking`);
                return false;
            }
        }

        if (!this._dataObject.fields.fieldExistsInView(this._dataObject.softDeleteField!)) {
            logger.warn(`${this._dataObject.id}: ${this._dataObject.viewName} does not have ${this._dataObject.softDeleteField} column`);
            return false;
        }

        const item: DataItemModel<T> & { o_DeleteConfirm?: boolean } = this._dataObject.storage.data[pIndex] ?? pItem;
        if (item == null) {
            logger.warn(`${this._dataObject.id} item with index ${pIndex} does not exist in storage`);
            return false;
        }
        if (item.o_DeleteConfirm) { return false; }
        if (this._dataObject.deleteConfirm) {
            const { default: confirmControl } = await import('o365.controls.confirm.ts');
            const confirmOptions = {
                message: this._dataObject.deleteConfirmOptions?.softDeleteMessage ?? this._dataObject.deleteConfirmOptions?.message ?? $t('Are you sure you want to mark as deleted?'),
                title: this._dataObject.deleteConfirmOptions?.title ?? $t('Delete confirm'),
                btnTextOk: this._dataObject.deleteConfirmOptions?.btnTextOk ?? $t('Delete'),
                btnTextCancel: this._dataObject.deleteConfirmOptions?.btnClassCancel,
                btnClassOk: this._dataObject.deleteConfirmOptions?.btnClassOk ?? 'btn-primary',
                btnClassCancel: this._dataObject.deleteConfirmOptions?.btnClassCancel ?? 'btn-outline-primary',
            };
            try {
                item.o_DeleteConfirm = true;
                await confirmControl(confirmOptions);
            } catch (_) {
                return false;
            } finally {
                delete item.o_DeleteConfirm;
            }
        }

        const updateValueType = this._dataObject.fields[this._dataObject.softDeleteField!]!.type;
        let updateValue: null | boolean | Date = null;
        switch (updateValueType) {
            case 'bit':
                updateValue = true;
                break;
            default:
                updateValue = new Date();
        }
        const options: RecordSourceOptions & RecordSourceCancelableEvent = this.getOptions();
        options.uniqueTable = this._dataObject.uniqueTable;
        options.values = {
            PrimKey: item.PrimKey,
            [this._dataObject.softDeleteField!]: updateValue
        };
      //  options.operation = 'softDelete';
        this._dataObject.emit('BeforeSoftDelete', options, item);
        if (options.cancelEvent === true) { return false; }

        item.state.isDeleting = true;
        try {
            await this._dataObject.dataHandler.softDelete(options);
            this._dataObject.storage.removeItem(item.index);
            if (this._dataObject.state.rowCount) {
                this._dataObject.state.rowCount -= 1;
            }
            this._dataObject.emit('AfterSoftDelete', options, item);
            if (this._dataObject.hasDynamicLoading) {
                // this._dataObject.dynamicLoading.dataLoaded(this._dataObject.data, { skip: 0 });
                this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data, { skip: 0 });
            }

            if (item.current) {
                if (this._dataObject.data[pIndex] != null) {
                    this._dataObject.setCurrentIndex(pIndex, true);
                } else if (pIndex - 1 >= 0 && this._dataObject.data[pIndex - 1] != null) {
                    this._dataObject.setCurrentIndex(pIndex - 1, true);
                } else {
                    this._dataObject.unsetCurrentIndex();
                }
            } else if (this._dataObject.currentIndex != null && this._dataObject.currentIndex > pIndex) {
                // Items shifted, update current index without state changes
                this._dataObject.updateCurrentIndex(this._dataObject.currentIndex - 1);
            }
            return true;
        } catch (ex) {
            item.updateError(ex as Error);
            return false;
        }
    }

    async bulkSaveItems(pItems: DataItemModel<T>[]) {
        const updateItems = pItems.filter(item => item.hasChanges && !item.isNewRecord);
        const createItems = pItems.filter(item => item.hasChanges && item.isNewRecord);
        const uniqueKey = this._dataObject.fields.uniqueField ?? 'PrimKey';
        const updateItemsKeyMap = new Map<string, number>();
        updateItems.forEach((item, index) => {
            updateItemsKeyMap.set(item[uniqueKey], index);
        });
        const promises: Promise<any>[] = [];
        if (updateItems.length > 0) {
            const options: RecordSourceOptions & IUpdateOptions = this.getOptions();
            options.bulk = true;
            options.key = uniqueKey;
            const changes = updateItems.map(item => {
                return {
                    [options.key!]: item[options.key!],
                    ...item.changes
                };
            });
            options.values = {
                data: changes
            };
            options.uniqueTable = this._dataObject.uniqueTable;
            options.whereClause = '';
            promises.push(this._dataObject.dataHandler.update(options).then(() => {
                switch (uniqueKey) {
                    case 'PrimKey':
                        const promises = updateItems.map(item => this.refreshRowByPrimKey(item.primKey!));
                        return Promise.all(promises).then(data => {
                            return data! as DataItemModel<T>[];
                        });
                    case 'ID':
                        const promises2 = updateItems.map(item => this.refreshRowById(item.ID!));
                        return Promise.all(promises2).then(data => {
                            return data! as DataItemModel<T>[];
                        });
                    default:
                        return this.refreshRowsByFilter(`[${uniqueKey}] IN (${updateItems.map(item => `'${item[uniqueKey]}'`).join(',')})`);
                }
            }));
        }

        if (createItems.length > 0) {
            promises.push(this.bulkCreateItems(createItems));
        }

        await Promise.all(promises);
    }

    /** Bulk delete items by prim keys */
    async bulkDeleteByPrimKeys(pPrimKeys: string[], pOptions?: { confirm?: boolean }) {
        if (pOptions?.confirm == null ? this._dataObject.deleteConfirm : pOptions.confirm) {
            const { default: confirmControl } = await import('o365.controls.confirm.ts');
            const confirmOptions = {
                message: $t(`Are you sure you want to delete ${pPrimKeys.length} ${pPrimKeys.length === 1 ? 'row' : 'rows'}?`),
                title: $t('Delete confirm'),
                btnTextOk: $t('Delete'),
                btnClassOk: 'btn-primary',
                btnClassCancel: 'btn-outline-primary',
            };
            try {
                await confirmControl(confirmOptions);
            } catch (_) {
                return false;
            }
        }
        const options: RecordSourceOptions & RecordSourceCancelableEvent & IDestroyOptions = this.getOptions();
        options.bulk = true;
        options.uniqueTable = this._dataObject.uniqueTable;
        options.valuelist = pPrimKeys;

        if (pPrimKeys.length > 0) {
            const { promiseAlert } = await import('o365.controls.alert.ts');
            let alertInstance: ReturnType<typeof promiseAlert> | null = null;
            try {
                const destroyPromise = this._dataObject.dataHandler.destroy(options);
                alertInstance = promiseAlert($t(`Deleting ${pPrimKeys.length} ${pPrimKeys.length === 1 ? 'row' : 'rows'}`), destroyPromise, 'info');
                const result = await destroyPromise;
                this._dataObject.storage.removeItemsByPrimKeys(pPrimKeys);
                if (result) {
                    alertInstance.updateMessage($t('Operation complete!'))
                    this._dataObject.load()
                }
                return true;
            } catch (_ex) {
                if (alertInstance) {
                    alertInstance.close();
                    alertInstance = null;
                }
                return false;
            } finally {
                if (alertInstance) {
                    window.setTimeout(() => {
                        alertInstance?.close();
                    }, 1000);
                }
            }
        } else {
            return false;
        }
    }

    /** Delete provided items in a single operation */
    async bulkDelete(pItems: DataItemModel<T>[], pOptions?: {
        /** Delete confirm override */
        confirm?: boolean
    }) {
        if (pOptions?.confirm == null ? this._dataObject.deleteConfirm : pOptions.confirm) {
            const { default: confirmControl } = await import('o365.controls.confirm.ts');
            const confirmOptions = {
                message: $t(`Are you sure you want to delete ${pItems.length} ${pItems.length === 1 ? 'row' : 'rows'}?`),
                title: $t('Delete confirm'),
                btnTextOk: $t('Delete'),
                btnClassOk: 'btn-primary',
                btnClassCancel: 'btn-outline-primary',
            };
            try {
                await confirmControl(confirmOptions);
            } catch (_) {
                return false;
            }
        }

        const options: RecordSourceOptions & RecordSourceCancelableEvent & IDestroyOptions = this.getOptions();
        options.bulk = true;

        options.uniqueTable = this._dataObject.uniqueTable;

        options.valuelist = pItems.filter(row => row.primKey).map(row => row.primKey!);

        this._dataObject.emit('BeforeBulkDelete', options, pItems);
        if (options.cancelEvent === true) { return false; }

        pItems.forEach(row => {
            this._dataObject.emit('BeforeDelete', options, row)
            if (options.cancelEvent === true) {
                const valueIndex = options.valuelist!.findIndex(x => x === row.primKey);
                if (valueIndex !== -1) {
                    options.valuelist!.splice(valueIndex, 1);
                }
                delete options.cancelEvent;
                return;
            }
            row.state.isDeleting = true;
        });

        if (options.valuelist.length === 0) {
            return false;
        }
        const { promiseAlert } = await import('o365.controls.alert.ts');
        let alertInstance: ReturnType<typeof promiseAlert> | null = null;
        try {
            if (options.valuelist.length > 0) {
                const destroyPromise = this._dataObject.dataHandler.destroy(options);
                alertInstance = promiseAlert($t(`Deleting ${options.valuelist.length} ${options.valuelist.length === 1 ? 'row' : 'rows'}`), destroyPromise, 'info');
                await destroyPromise;
                alertInstance.updateMessage($t('Operation complete!'))
            }
            pItems.sort((a, b) => b.index - a.index).forEach(row => {
                if (!row.isBatchRecord) {
                    this._dataObject.storage.removeItem(row.index);
                }
            });
            pItems.forEach(item => {
                this._dataObject.emit('AfterDelete', options, item);
            });
            if (this._dataObject.hasDynamicLoading) {
                this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data, { skip: 0 });
            }

            const currentIndexInDeleted = pItems.findIndex(x => x.current);
            if (currentIndexInDeleted !== -1) {
                if (this._dataObject.data[currentIndexInDeleted] != null) {
                    this._dataObject.setCurrentIndex(currentIndexInDeleted, true);
                } else if (currentIndexInDeleted - 1 >= 0 && this._dataObject.data[currentIndexInDeleted - 1] != null) {
                    this._dataObject.setCurrentIndex(currentIndexInDeleted - 1, true);
                } else {
                    this._dataObject.unsetCurrentIndex();
                }
            } else {
                const currentItem = this._dataObject.data.find(x => x.current);
                if (currentItem && this._dataObject.currentIndex !== currentItem.index) {
                    this._dataObject.updateCurrentIndex(currentItem.index);
                }
            }
            this._dataObject.load();
            return true;
        } catch (ex) {
            if (alertInstance) {
                alertInstance.close();
                alertInstance = null;
            }
            pItems.forEach(row => row.updateError(ex as Error));
            return false;
        } finally {
            if (alertInstance) {
                window.setTimeout(() => {
                    alertInstance?.close();
                }, 1000);
            }
        }

    }

    /**
     * Bulk create insert items. Returns inserted PrimKeys
     */
    async bulkCreate(pData: Partial<T>[]): Promise<{ PrimKey: string }[]> {
        const options: RecordSourceOptions & ICreateOptions = this.getOptions();
        options.bulk = true;
        options.uniqueTable = this._dataObject.uniqueTable;
        const values: T[] = []
        pData.forEach(item => {
            let cleanedItem: ItemModel = {};
            Object.entries(item).forEach(([key, value]) => {
                if (value != null) {
                    cleanedItem[key] = value;
                }
            });
            values.push(cleanedItem as T);
        });
        options.values = {
            data: values
        };
        const result = await this._dataObject.dataHandler.create(options);
        console.log(result);
        return result as any;
    }

    /**
     * Bulk instert new DataItems
     * @param pData array of data items, all items must be new records, will throw an eror otherwise
     * @param pSettings additional options for skipping row retrieval after instert, etc.
     */
    async bulkCreateItems(pData: DataItemModel<T>[], pSettings?: {
        /** Skip item retrieval after a successful bulk insert */
        skipItemRetrieve?: boolean
    }) {
        const allItemsAreNew = pData.every(item => item.isNewRecord);
        if (!allItemsAreNew) {
            throw new Error('Only new records can be bulk inserted')
        }
        try {
            const bulkItems = await this.bulkCreate(pData.map(row => {
                let item = row.changes ?? {} as Partial<T>;
                if (this._dataObject.masterDetails.isSet) {
                    // This is a details DataObject, get bound master values
                    item = { ...item, ...this._dataObject.masterDetails.getMasterDetailRowForInsert() };
                }
                if (this._contextFilterSet && this._dataObject.fields.OrgUnit_ID && item.OrgUnit_ID === undefined) {
                    (item as any).OrgUnit_ID = context.id;
                }
                if (item.PrimKey) {
                    delete item.PrimKey;
                }
                if (row.defaultValues) {
                    // Get default values from DataItem
                    Object.keys(row.defaultValues).forEach((key: keyof T) => {
                        if (item == null) { item = {}; }
                        if (!item.hasOwnProperty(key) && row.defaultValues[key] != undefined) {
                            item[key] = row.defaultValues[key];
                        }
                    });
                }

                this._dataObject.fields.fields.filter(field => {
                    // Get default values from fields
                    if (item[field.name] === undefined) {
                        const defaultValue = typeof field.defaultValueFunction === 'function' ? field.defaultValueFunction() : field.defaultValue;
                        item[field.name as keyof T] = defaultValue;
                    }
                });
                return item;
            }))
            const primKeys = bulkItems.map(item => item.PrimKey);
            pData.forEach(item => item.state.isSaving = true);
            if (primKeys.length !== pData.length) {
                logger.warn(`${this._dataObject.id}.recordSource.bulkInsertItems: Bulk inserted primkeys length differs from provided the provied data length`);
                pData.forEach(item => item.reset());
                this._dataObject.load();
                return;
            } else if (!pSettings?.skipItemRetrieve) {
                const promises = pData.map((item, index) => {
                    const primKey = primKeys[index];
                    return (async () => {
                        const values = await this.getRowByPrimKey(primKey);
                        if (values == null || values.length !== 1) {
                            item.updateError(values == null ? $t('Could not retrieve values after bulk instert') : $t(`Item retrieve returned ${values.length} rows for this item`));
                        } else {
                            item.updateOrExtendItem(values[0]);
                            if (!item.isBatchRecord) {
                                this._dataObject.storage.updateItem(item.index, values[0], true);
                            } else {
                                const index = this._dataObject.batchData.getInversedIndex(item.index);
                                this._dataObject.batchData.storage.updateItem(index, values[0], true);
                            }
                            item.state.isNewRecord = false;
                        }
                    })();
                });
                await Promise.all(promises);
            } else {
                pData.forEach((item, index) => { (item.item as any).PrimKey = primKeys[index]; item.reset(); });
            }
        } catch (ex) {
            pData.forEach(item => item.state.isSaving = false);
            logger.error(ex);
            throw ex;
        }
    }

    /**
     * Send a request to delete a DataItem from the database. 
     * If no item provided will attempt to use current one.
     */
    deleteItem(pItem?: DataItemModel<T>) {
        if (pItem == null) {
            pItem = this._dataObject.current;
        }
        if (pItem == null) {
            throw new Error(`Cannot delete undefined item`);
        }
        return this.delete(pItem.index, pItem);
    }

    /** Set item as deleted */
    softDeleteItem(pItem?: DataItemModel<T>) {
        if (!pItem) {
            pItem = this._dataObject.current;
        }
        if (pItem == null) {
            throw new Error(`Cannot soft delete undefined item`);
        }

        return this.softDelete(pItem.index, pItem);
    }

    /**
     * Update the previous where clause with the current combined one. 
     * This is executed after every data object load
     */
    updatePreviousWhereClause() {
        this._prevWhereClause = this.combinedWhereClause;
        this._prevWhereClauseSingle = this.whereClause;
    }

    async getAllRows(pFields: string[], pOptions?: {
        withSortOrder?: boolean
    }) {
        const options = this.getOptions();
        options.maxRecords = -1;
        options.fields = pFields.map(field => ({ name: field }));
        if (pOptions?.withSortOrder) {
            this.getSortOrder().forEach((sort, index) => {
                Object.keys(sort).forEach(field => {
                    const requestField = options.fields!.find(x => x.name === field);
                    if (requestField) {
                        requestField.sortDirection = sort[field] || 'asc';
                        requestField.sortOrder = index + 1;
                    } else {
                        options.fields!.push({ name: field, sortOrder: index + 1, sortDirection: sort[field] || 'asc' });
                    }
                });
            });

        }
        const result = await this.retrieve(options);
        return result as unknown as Partial<T>[];
    }

    /**
     * Retrieve all IDs with the current record source options.
     * Also returns PrimKeys
     */
    async getAllIDs(pOptions?: {
        /** Will add sort order columns to the request */
        withSortOrder: boolean
    }): Promise<{ ID: number, PrimKey: string }[]> {
        const result = await this.getAllRows(['ID'], pOptions);
        return result as unknown as { ID: number, PrimKey: string }[];
    }

    /**
     * Retrieve all uniqueKeys with the current record source options.
     * Also returns PrimKeys
     */
    async getAllUniqueKeys(pOptions?: {
        /** Will add sort order columns to the request */
        withSortOrder: boolean
    }): Promise<{ ID: number, PrimKey: string, [key: string]: string | number }[]> {
        if (this._dataObject.fields.uniqueField == null) { return Promise.resolve([]); }
        const result = await this.getAllRows([this._dataObject.fields.uniqueField], pOptions);
        return result as unknown as { ID: number, PrimKey: string }[];
    }

    /**
     * Set fields that should be used when retrieving
     * @param pFields
     */
    setSelectFields(pFields: string[]) {
        this._selectedFields = [];
        this._addSelectedFields(pFields);
        this._addSelectedField('ID');
    }

    /**
     * Get missing provided fields values for items in storage.  
     * Can also be used to just refresh some field values.
     * 
     * @param {string[]} pFields array of fields to refresh
     * @param {boolean} [pForceRefresh] when true will refresh the given fields for all rows
     */
    async loadFields(pFields: string[], pForceRefresh?: boolean) {
        const itemsToUpdate: DataItemModel<T>[] = [];
        this._dataObject.storage.data.forEach(item => {
            const keys = Object.keys(item.item);
            pFields.some(field => {
                if (!keys.includes(field) || pForceRefresh) {
                    itemsToUpdate.push(item);
                    return true;
                } else {
                    return false;
                }
            });
        });

        if (itemsToUpdate.length === 0) { return []; }

        const fields: RecordSourceFieldType[] = pFields.map(field => ({ name: field }));
        fields.push({
            name: 'ID',
            sortDirection: 'asc',
            sortOrder: 1
        });

        const loadFieldsOperation = new BulkOperation<DataItemModel<T>, T>({
            bulkOperation: async (pItems) => {
                let idIsString = false;
                try {
                    idIsString = !Number.isInteger(pItems[0].value.ID);
                } catch (_ex) {
                    idIsString = true;
                }
                const result = await this.retrieve({
                    fields: fields,
                    filterString: `ID IN (${pItems.map(item => idIsString ? `'${item.value.ID}'` : `${item.value.ID}`).join(',')})`,
                    maxRecords: itemsToUpdate.length,
                    skip: 0
                });
                const idsMap = new Map<any, number>();
                result.forEach((item, index) => { idsMap.set(item.ID, index) });
                pItems.forEach(item => {
                    const index = idsMap.get(item.value.ID);
                    if (index != null) {
                        item.res(result[index]);
                    } else {
                        item.rej(new Error(`Could not retrive fields for ID ${item.value.ID}`));
                    }
                });
            }
        });

        const promises = itemsToUpdate.map(item => loadFieldsOperation.addToQueue(item));
        const result2 = await Promise.all(promises).then(items => {
            return items.map(x => x!);
        });

        return result2;
    }

    /**
     * Update the selected fields and load new values
     * @param pFields New visible fields
     */
    manageAndLoadSelectFields(pFields: string[]) {
        if (this._selectedFieldsLoadDebounce) { window.clearTimeout(this._selectedFieldsLoadDebounce); }

        this._selectedFieldsLoadDebounce = window.setTimeout(() => {
            this._manageAndLoadSelectFields(pFields);
        }, 200);
    }

    /** Implementation of on demand fields */
    private async _manageAndLoadSelectFields(pFelds: string[]) {
        const fieldsToLoad: string[] = [];

        const prevFields = this._selectedFields.map(field => field.name);
        this.setSelectFields(pFelds);

        this._selectedFields.forEach(field => {
            if (!prevFields.includes(field.name)) {
                fieldsToLoad.push(field.name);
            }
        });

        if (fieldsToLoad.length > 0) {
            const data = await this.loadFields(fieldsToLoad);
            data.forEach(item => {
                this._dataObject.storage.updateItemsById(item.ID, item, true);
            });
        }
    }

    /** Helper function for showing toast messages */
    private async _showAlert(pMessage: string, pType: 'warning' | 'danger') {
        const { default: o365_alert } = await import('o365.controls.alert.ts');
        o365_alert(pMessage, pType);
    }

    /** ??? */
    private _addSelectedFields(pFields: string[]) {
        if (pFields == null || pFields.length === 0) { return [] };

        const addedFields: string[] = [];
        pFields.forEach(field => {
            this._addSelectedField(field).forEach(addedField => addedFields.push(addedField));
        });

        this._dataObject.fields.loadAlways.forEach(field => {
            // Add always loaded fields
            this._addSelectedField(field);
        });

        this._dataObject.fields.fields.filter(field => field.sortDirection).forEach(field => {
            // Add sorted fields
            this._addSelectedField(field.name);
        });

        return addedFields;
    }

    private _addSelectedField(pField: string) {
        if (this._selectedFields.find(field => field.name === pField)) {
            return [];
        }

        const addedFields: string[] = [];

        const field = this._dataObject.fields[pField];

        if (field) {
            this._selectedFields.push(field);
            addedFields.push(field.name);
            this._addSelectedFields(field.dependantFields).forEach(addedField => {
                addedFields.push(addedField);
            });
            return addedFields;
        } else {
            logger.warn(`Field not found: ${pField}`);
            return [];
        }
    }

    /** Retrieve data with IN operator */
    async bulkRetrieve(pValues: (string | number)[], pField: string, pWithWhereClause = true) {
        const options = {
            whereClause: pValues.length == 1
                ? `${pField} = '${pValues[0]}'`
                : `${pField} IN (${pValues.map(x => `'${x}'`).join(',')})`,
            maxRecords: -1,
            skip: 0
        };
        const whereClause = this.getWhereClause();
        if (pWithWhereClause && whereClause) {
            options.whereClause = `(${whereClause}) AND (${options.whereClause})` 
        }

        return await this.retrieve(options);
    }

    /** Helper method for getting a new promise with external resolve function */
    private _getPromiseWithResolve() {
        let resolve = () => { };
        const promise = new Promise<void>(res => { resolve = res });
        return { promise, resolve };
    }

    //--- Compatibility functions ---
    // @ts-ignore
    private async loadRowCounts(pOptions: any) {
        return this.loadRowCount(pOptions);
    }

    appendSortByFields<T extends RecordSourceFieldType[]>(pFields: T, pOverrides?: Record<string, any>) {
        for (const col of this._dataObject.fields.fields) {
            const existingField = pFields.find(x => x.name === col.name);
            if (col.sortDirection && col.sortOrder && !existingField) {
                if (pOverrides) {
                    pFields.push({ ...col.item, ...pOverrides });
                } else {
                    pFields.push({ ...col.item });
                }
            }
            if (col.sortDirection && col.sortOrder && existingField) {
                const index = pFields.findIndex(x => x.name === col.name);
                pFields[index].sortOrder = col.item.sortOrder;
                pFields[index].sortDirection = col.item.sortDirection;
            }
        }
    }
}
